var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"checking-account-modal","centered":"","title":_vm.getTitle,"no-close-on-backdrop":"","cancel-title":_vm.$t('client.cancel'),"cancel-variant":"danger","ok-title":_vm.editItem.id ? _vm.$t('change') : _vm.$t('save'),"ok-variant":_vm.editItem.id ? 'warning' : 'success'},on:{"show":_vm.openModal,"ok":_vm.onSubmit}},[_c('validation-observer',{ref:"ContractList"},[_c('b-form-group',{staticClass:"my-1",attrs:{"label":"Контрагент (ссылка на контрагента): "}},[_c('validation-provider',{attrs:{"name":"Контрагент ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.GET_CONTRACTORS_LIST,"reduce":function (item) { return item.id; },"label":"title"},model:{value:(_vm.formData.contractor),callback:function ($$v) {_vm.$set(_vm.formData, "contractor", $$v)},expression:"formData.contractor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"Наименование РС: "}},[_c('validation-provider',{attrs:{"name":"Наименование РС ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"РС: "}},[_c('validation-provider',{attrs:{"name":"РС","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.settlement_account),callback:function ($$v) {_vm.$set(_vm.formData, "settlement_account", $$v)},expression:"formData.settlement_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"БИК: "}},[_c('validation-provider',{attrs:{"name":"БИК ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.bic),callback:function ($$v) {_vm.$set(_vm.formData, "bic", $$v)},expression:"formData.bic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"Наименование банка: "}},[_c('validation-provider',{attrs:{"name":"Наименование банка ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.bank_name),callback:function ($$v) {_vm.$set(_vm.formData, "bank_name", $$v)},expression:"formData.bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"КС: "}},[_c('validation-provider',{attrs:{"name":"КС","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.correspondent_account),callback:function ($$v) {_vm.$set(_vm.formData, "correspondent_account", $$v)},expression:"formData.correspondent_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }