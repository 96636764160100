<template>
  <b-modal
    id="checking-account-modal"
    centered
    :title="getTitle"
    no-close-on-backdrop
    @show="openModal"
    :cancel-title="$t('client.cancel')"
    cancel-variant="danger"
    :ok-title="editItem.id ? $t('change') : $t('save')"
    :ok-variant="editItem.id ? 'warning' : 'success'"
    @ok="onSubmit"
  >
    <validation-observer ref="ContractList">
      <b-form-group label="Контрагент (ссылка на контрагента): " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="Контрагент "
          rules="required"
        >
          <v-select
            v-model="formData.contractor"
            :options="GET_CONTRACTORS_LIST"
            :reduce="item => item.id"
            label="title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Наименование РС: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="Наименование РС "
          rules="required"
        >
          <b-form-input v-model="formData.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="РС: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="РС"
          rules="required|integer"
        >
          <b-form-input v-model="formData.settlement_account" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label="БИК: " class="my-1">
        <validation-provider #default="{ errors }" name="БИК " rules="required">
          <b-form-input v-model="formData.bic" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Наименование банка: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="Наименование банка "
          rules="required"
        >
          <b-form-input v-model="formData.bank_name" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="КС: " class="my-1">
        <validation-provider #default="{ errors }" name="КС" rules="required">
          <b-form-input v-model="formData.correspondent_account" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  BCol,
  BFormFile,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, length } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import VSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    Datepicker,
    BFormCheckbox,
    BFormFile,
    BRow,
    BCol,
    VSelect,
    BBadge,
    Cleave,
  },

  props: {
    editItem: {
      type: Object,
    },
  },

  data() {
    return {
      ru,
      integer,
      length,
      required,
      formData: {
        contractor: '',
        settlement_account: '',
        bic: '',
        bank_name: '',
        correspondent_account: '',
        title: '',
      },
    }
  },

  computed: {
    ...mapGetters('knowledgeBase', ['GET_CONTRACTORS_LIST']),

    getTitle() {
      return this.editItem?.id ? 'Редактировать ' : 'Добавить'
    },

    typeofFile() {
      return typeof this.file
    },

    isFileInput() {
      if (this.file?.title) {
        return false
      } else {
        return true
      }
    },
  },

  mounted() {
    this.FETCH_CONTRACTORS_LIST()
  },

  methods: {
    ...mapActions('knowledgeBase', ['FETCH_CONTRACTORS_LIST']),

    ...mapActions('knowledgeBase', [
      'CREATE_SETTLEMENT_ACCOUNT',
      'UPDATE_SETTLEMENT_ACCOUNT',
    ]),

    async onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      const response = await this.$refs.ContractList.validate()
      if (!response) {
        return
      }

      try {
        if (this.editItem?.id) {
          await this.UPDATE_SETTLEMENT_ACCOUNT({
            data: this.formData,
            id: this.editItem.id,
          })
        } else {
          // console.log('datadff', this.formData)

          await this.CREATE_SETTLEMENT_ACCOUNT(this.formData)
        }

        this.$emit('refresh')

        this.$nextTick(() => {
          this.$_successToast(this.$t('general.success'))
          this.$bvModal.hide('checking-account-modal')
        })
      } catch (error) {
        console.log('error', error)
      }
    },

    openModal() {
      if (this.editItem.id) {
        const {
          contractor,
          settlement_account,
          bic,
          bank_name,
          correspondent_account,
          title,
        } = this.editItem

        this.formData.contractor = contractor.id
        this.formData.title = title
        this.formData.settlement_account = settlement_account
        this.formData.bic = bic
        this.formData.bank_name = bank_name
        this.formData.correspondent_account = correspondent_account
      } else {
        this.formData.contractor = ''
        this.formData.settlement_account = ''
        this.formData.title = ''
        this.formData.bic = ''
        this.formData.bank_name = ''
        this.formData.correspondent_account = ''
      }
    },
  },
}
</script>

<style scoped>
#username {
  text-transform: uppercase;
}
.file-border {
  border: 1px solid black;
  border-radius: 6px;
}

.file-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
