<template>
  <div>
    <BRow>
      <BCol md="2">
        <AppSearchInput @callback="onSearch" :placeholder="$t('search')" />
      </BCol>
      <BCol md="2">
        <b-form-group class=" ml-2">
          <label for="">Контрагентов по ИНН :</label>
          <v-select
            v-model="params.contractor__inn"
            :options="GET_CONTRACTORS_LIST"
            @input="onFilter"
            :reduce="item => item.id"
            label="inn"
          /> </b-form-group
      ></BCol>
      <BCol md="8" class="d-flex justify-content-end align-items-center">
        <b-button variant="primary" @click="addData">
          <feather-icon icon="PlusIcon" />
          {{ $t('add') }}
        </b-button>
      </BCol>
    </BRow>

    <b-card class="mt-1">
      <b-table
        :busy="busy"
        id="contract-table"
        class="mt-2 "
        :fields="fields"
        :items="GET_SETTLEMENT_ACCOUNTS.results"
        show-empty
        :empty-text="$t('administration.no_records')"
        responsive
      >
        <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
        <template #empty>
          <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
        </template>
        <template #emptyfiltered="scope">
          <h4 class="text-center">{{ scope.emptyFilteredText }}</h4>
        </template>
        <template #head()="props">
          <span>{{ $t(props.label) }}</span>
        </template>

        <template #cell(monthly_limit)="data">
          {{ $_moneyFormatter(data.item.monthly_limit) }}
        </template>

        <template #cell(is_active)="row" class="text-center">
          <b-form-checkbox
            :checked="row.item.is_active"
            name="check-button"
            class="custom-control-primary"
            switch
            inline
            disabled
          >
          </b-form-checkbox>
        </template>

        <template #cell(start_date)="row" class="text-center">
          <div>
            {{ row.item.start_date }}
          </div>
          <div>
            {{ row.item.end_date }}
          </div>
        </template>

        <template #cell(is_auto_confirmable)="row" class="text-center">
          <b-form-checkbox
            :checked="row.item.is_auto_confirmable"
            name="check-button"
            class="custom-control-primary"
            switch
            inline
            disabled
          >
          </b-form-checkbox>
        </template>

        <template #cell(author)="data">
          <b-avatar
            :src="data.item.author.photo"
            v-b-tooltip.hover
            class="pull-up mr-1"
            :title="data.item.author.full_name"
          />
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-end">
            <b-button
              variant="warning"
              size="sm"
              class="mr-1"
              @click="editData(item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button size="sm" variant="danger" @click="deleteItem(item.id)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-card>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap">{{ $t('pagination.show_from') }}</span>
          <b-form-select
            v-model="params.page_size"
            :options="['10', '15', '25']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="text-nowrap"> {{ $t('pagination.lines') }} </span>
        </div>

        <b-pagination
          v-model="params.page"
          :total-rows="GET_SETTLEMENT_ACCOUNTS.count"
          :per-page="params.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="changePage"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>

    <EditModal :editItem="editItem" @refresh="refresh" />

    <FileListModal :propsItem="fileListItem" />
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BButton,
  BFormSelect,
  BCard,
  BAvatar,
  VBTooltip,
  BRow,
  BSpinner,
  BCol,
} from 'bootstrap-vue'
import EditModal from './components/EditModal.vue'
import { mapActions, mapGetters } from 'vuex'
import AppSearchInput from '@/components/AppSearchInput.vue'
import VSelect from 'vue-select'

import FileListModal from './components/FileListModal.vue'

export default {
  components: {
    BTable,
    BPagination,
    BFormGroup,
      BSpinner,
    BFormInput,
    BFormCheckbox,
    BButton,
    EditModal,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    AppSearchInput,
    BCard,
    VSelect,
    BAvatar,
    FileListModal,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      editItem: {},
      params: {
        page: 1,
        page_size: 10,
        search: '',
        contractor__inn: '',
      },
      busy: false,

      fileListItem: {},
      fields: [
        // {
        //   label: 'ИД',
        //   key: 'id',
        //   thClass: 'tableHeadClass',
        // },
        {
          label: 'Контрагент',
          key: 'contractor.title',
          thClass: 'tableHeadClass',
        },

        {
          label: 'ИНН',
          key: 'contractor.inn',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Наименование РС',
          key: 'title',
          thClass: 'tableHeadClass',
        },

        {
          label: 'РС',
          key: 'settlement_account',
          thClass: 'tableHeadClass',
        },

        {
          label: 'БИК',
          key: 'bic',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Наименование банка',
          key: 'bank_name',
          thClass: 'tableHeadClass',
        },
        {
          label: 'КС',
          key: 'correspondent_account',
          thClass: 'tableHeadClass',
        },

        {
          label: this.$t('actions'),
          key: 'actions',
          thClass: 'tableHeadClass action-head',
          tdClass: 'width-150',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('knowledgeBase', [
      'GET_SETTLEMENT_ACCOUNTS',
      'GET_CONTRACTORS_LIST',
    ]),
  },
  mounted() {
    // this.FETCH_CONTRACTORS_LIST()
    this.refresh()
  },
  methods: {
    ...mapActions('knowledgeBase', [
      'FETCH_SETTLEMENT_ACCOUNTS',
      'DELETE_SETTLEMENT_ACCOUNT',
      'FETCH_CONTRACTORS_LIST',
    ]),

    async refresh() {
      try {
        this.busy = true
        await this.FETCH_SETTLEMENT_ACCOUNTS(this.params)
      } catch (error) {
        this.$_errorToast(this.$t('error'))
      }
      this.busy = false
    },

    onSearch(text) {
      this.params.search = text
      this.params.page = 1
      this.refresh()
    },

    onFilter(value) {
      this.params.contractor__inn = value
      this.refresh()
    },

    changePage(value) {
      this.params.page = value
      this.refresh()
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.refresh()
    },
    setFile(item) {
      this.file = item.file

      this.$nextTick(() => {
        this.$bvModal.show('see-doc-modal')
      })
    },

    editData(item) {
      this.editItem = item

      this.$nextTick(() => {
        this.$bvModal.show('checking-account-modal')
      })
    },

    openFileList(item) {
      this.fileListItem = item

      this.$nextTick(() => {
        this.$bvModal.show('contract-modal')
      })
    },
    addData() {
      this.editItem = {}

      this.$nextTick(() => {
        this.$bvModal.show('checking-account-modal')
      })
    },

    deleteItem(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          if (this.GET_SETTLEMENT_ACCOUNTS.results.length === 1) {
            if (this.params.page > 1) --this.params.page
          }
          this.DELETE_SETTLEMENT_ACCOUNT(id)
            .then(() => {
              this.refresh()
              this.$_successToast(this.$t('general.success'))
              this.params.page = 1
            })
            .catch(() => {
              this.$_errorToast(this.$t('error'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
#contract-table .tableHeadClass {
  vertical-align: middle;
}

#contract-table .action-head {
  text-align: center;
}
</style>


<style scoped>
.vs--single {
  background: white;
  border-radius: 5px;
}
.vs--single::placeholder {
  color: blue;
}
</style>

